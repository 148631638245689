import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFound = () => (
  <Layout>
    <SEO title="Not found" ogUrl="/404" />

    <section className="wrapper style2 alt">
      <div className="inner">
        <header className="major">
          <h2>Not found</h2>
        </header>

        <p>Oops! Let me know if you think you've reached this page in error!</p>
      </div>
    </section>
  </Layout>
);

export default NotFound;
